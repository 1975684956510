<template>
  <div class="logistics-wrap">
    <div class="logistics-inner">
      <!-- 收货地址 -->
      <!-- <div class="logistics-item" v-show="logisticsList[0].status == '已签收'">
        <div class="heder">
          <span class="time"></span>
          <div class="info_txt ask">收 </div>
          <div class="contxt">
            <p class="collect">[收货地址] {{ logisticsData.address }}{{ logisticsData.tellPhone }}</p>
          </div>
        </div>
      </div> -->
      <!-- 物流 -->
      <div class="logistics-item" v-for="(item,index) in logisticsList" :key="index">
        <div class="heder">
          <span class="time" :class="{'timeSingle' : !item.status}">{{item.ftime}}</span>
          <div class="info_txt" :class="{'firstIcon' : index == 0}" v-if="item.stateImg"><img :src="item.stateImg" alt=""></div>
          <div class="info_txt spot" :class="{'firstIcon' : index == 0}" v-else></div>
          <div class="contxt" :class="{'contxtSingle' : !item.status}">
            <span class="name" :class="{'firstName' : index == 0}" v-if="item.status">{{item.status}}</span>
            <p class="collect">{{item.context}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    // 物流数据
    logisticsData: {
      type: Object,
      default: () => ({}),
    },
  },
  // 数据对象
  data () {
    return {
      logisticsList: [], //物流
      // 快递状态
      logisticsState: {
        签收: "已签收",
        代签: "已签收",
        派件: "派件中",
        在途: "运输中",
        揽收: "已揽件",
      },
      // 状态对应的图片logo
      logisticsImg: {
        签收: require("../../assets/image/order/logistics_icon1.png"),
        代签: require("../../assets/image/order/logistics_icon1.png"),
        派件: require("../../assets/image/order/logistics_icon2.png"),
        在途: require("../../assets/image/order/logistics_icon3.png"),
        揽收: require("../../assets/image/order/logistics_icon4.png"),
        已下单: require("../../assets/image/order/logistics_icon5.png"),
        疑难: require("../../assets/image/order/logistics_icon6.png"),
        退签: require("../../assets/image/order/logistics_icon7.png"),
        清关: require("../../assets/image/order/logistics_icon8.png"),
        拒签: require("../../assets/image/order/logistics_icon9.png"),
      },
    }
  },
  // 事件处理器
  methods: {
    changeData(){
      if (!this.logisticsData) {
        return;
      }
      
      let logisticsList = JSON.parse(
        JSON.stringify(this.logisticsData.logisticsInfo.data)
      );
      if (!logisticsList) {
        return;
      }
      
      let logisticsItem = {};
      logisticsList = logisticsList.map((item, index) => {
        // 默认每一条数据参数
        logisticsItem = {
          areaCode: item.areaCode,
          areaName: item.areaName,
          context: item.context,
          ftime: item.ftime,
          time: item.time,
        };
        // 第一条加状态和状态logo
        if (index == 0) {
          return {
            ...logisticsItem,
            status: this.logisticsState[item.status],
            stateImg: this.logisticsImg[item.status],
          };
        } else if (index !== 0) {
          // 当前状态与上一条状态一致
          if (item.status == logisticsList[index - 1].status) {
            return { ...logisticsItem };
          } else {
            return {
              ...logisticsItem,
              status: this.logisticsState[item.status],
              stateImg: this.logisticsImg[item.status],
            };
          }
        }
      });
      logisticsList.push({
        context: "商品已经下单",
        ftime: this.logisticsData.paymentTime,
        status: "已下单",
        stateImg: this.logisticsImg["已下单"],
      });
      this.logisticsList = logisticsList;
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    logisticsData: {
      handler: function (val) {
        // 处理数据
        this.changeData();
      },
      immediate: true,
      deep: true,
    },
  }
}
</script>

<style lang="less" scoped>
.logistics-wrap{
  width: 100%;
  height: 100%;
  max-height: 270px;
  overflow-y: scroll;
  margin:10px 16px;
  .logistics-inner{
    margin-left: 15%;
    .logistics-item{
      .heder{
        display: flex;
        .time{
          display: inline-block;
          min-width: 140px;
          line-height: 48px;
          font-size: 14px;
          color: #666666;
        }
        .timeSingle{
          line-height: 14px;
        }
        .info_txt{
          width: 48px;
          height: 48px;
          min-width: 48px;
          margin-left: 27px;
          margin-right: 15px;
          font-size: 22px;
          color: #fff;
          background-color: #ccc;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 10;
          img{
            width: 100%;
          }
        }
        .firstIcon{
          background-color: @theme;
        }
        .spot{
          width: 11px;
          min-width: 11px;
          height: 11px; 
          margin-left: 45px;
          margin-right: 34px;
        }
        .ask{
          background-color: @theme;
        }
        .contxt{
          padding-top: 18px;
          padding-bottom: 30px;
          position: relative;
          .name{
            display: inline-block;
            font-weight: 500;
            color: #333333;
            margin-bottom: 8px;
          }
          .firstName{
            color: @theme;
          }
          .collect{
            line-height: 18px;
            font-size: 14px;
            color: #666666;
          }
          &::before{
            content: '';
            position: absolute;
            z-index: 5;
            left: -40px;
            top: 0;
            width: 1px;
            height: 100%;
            background: #EBEBEB;
          }
        }
        .contxtSingle{
          padding-top: 0;
          padding-bottom: 35px;
        }
      }
    }
    .logistics-item:last-child{
      .heder .contxt{
        padding-bottom: 10px;
        &::before{
          background:#fff;
          height: 0;
        }
      }
    }
  }
}
/* 设置滚动条的样式 */
.logistics-wrap::-webkit-scrollbar {
  width:8px;
}
/* 滚动槽 */
.logistics-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(243,243,243);
  border-radius:10px;
  background:rgba(243,243,243);
}
/* 滚动条滑块 */
.logistics-wrap::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(196,196,196);
  -webkit-box-shadow:inset006pxrgba(196,196,196);
}
</style>
